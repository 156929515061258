import React, { Component } from 'react';

import moment from 'moment';
import { FaMessage, FaTrashCan, FaCirclePause, FaCirclePlay, FaCircleQuestion, FaEllipsis, FaHeart } from 'react-icons/fa6';
import { FaThumbsUp,FaAngleLeft, FaAngleRight, FaBan, FaUserCircle} from 'react-icons/fa';
import {AiOutlineLike} from 'react-icons/ai';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { MdLaunch } from 'react-icons/md';
import { SlPencil } from 'react-icons/sl';

import MongoProjectEngine from '../../../../../engine/mongodb/projects';
import MongoProjectUpdateCommentEngine from '../../../../../engine/mongodb/projectupdatecomments';
import MongoProjectUpdateCommentLikeEngine from '../../../../../engine/mongodb/projectupdatecommentlikes';
import MongoProjectUpdateEngine from '../../../../../engine/mongodb/projectupdates';
import MongoProjectUpdateLikeEngine from '../../../../../engine/mongodb/projectupdatelikes';
import Formatter from '../../../../../engine/formatter';
import Utility from '../../../../../engine/utility';

import { HEART_VALUE } from '../../../../../engine/constants/globalconstants';
import "./styles.css";
import { Link } from 'react-router-dom';
import icon_heart_full from '../../../../../res/images/icon_heart_full.png';
import img_crowdfunding from '../../../../../res/images/img_crowdfunding.png';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Player, BigPlayButton, ControlBar } from "video-react";
import "video-react/dist/video-react.css";

const win = window.innerWidth;
const bodyHorizontalMargin = 10;
const mediaAspectRatio = 353 / 238;

class ProjectPostPanel extends Component {
  constructor(props) {
    super(props);

    var panelWidth = this.props.width; //win.width - bodyHorizontalMargin * 2;
    var mediaWidth = panelWidth;
    var mediaHeight = mediaWidth / mediaAspectRatio;

    this.state = {
      projectLogo: null,

      likedByMe: false,
      likeCount: 0,

      currentMediaSlideIndex: 0,

      wholeComment: false,
      commentWordCount: 0,
      comment: "",

      commentCount: 0,
      lastComment: null,

      availableHearts: this.props.availableHearts,
      commentText: "",
      commentHeartCount: 0,

      panelWidth: panelWidth,

      mediaWidth: mediaWidth,
      mediaHeight: mediaHeight,

      currentPhotoSlideIndex: 0,
      currentMedia: null,

      currentVideoPaused: true,

      showPledgeModal: false,

      loading: false,
    };

    this.videoPlayers = [];
    this.photosScroll = null;
    this.player = null;

    this.commentActionSheet = null;
    this.enableCrowdfunding = false;
  }

  componentDidMount = () => {
    this.onScreenFocused();
  }

  componentWillUnmount() {
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.post == undefined || prevProps.post == null) {
      if (this.props.post != undefined && this.props.post != null) {
        this.onScreenFocused();
      }
    } else {
      if (this.props.post != undefined && this.props.post != null) {
        if (prevProps.post.docId != this.props.post.docId) {
          this.onScreenFocused();
        }
      }
    }

    if (prevProps.availableHearts != this.props.availableHearts) {
      this.setState({
        availableHearts: this.props.availableHearts,
      });
    }
  }

  onScreenFocused = async () => {
    this.setState({
      loading: true,
    });

    var update = this.props.post;
    var lastComment = null;

    try {
      lastComment = await MongoProjectUpdateCommentEngine.getLatestByUpdateId(update.docId);
    } catch(error) {
      console.log("[ProjectPostPanel] onScreenFocused() -> error:", error);
    }

    if (lastComment != null) {
      var likedByMe = false;
      /* if (this.props.signedUser != null) {
        likedByMe = await MongoProjectUpdateCommentLikeEngine.isLiked(lastComment.docId, this.props.signedUser.docId);
      } */
      lastComment.likedByMe = likedByMe;
    }

    var likedByMe = false;
    /* if (this.props.signedUser) {
      likedByMe = await MongoProjectUpdateLikeEngine.isLiked(update.docId, this.props.signedUser.docId);
    } */

    this.setState({
      likedByMe: likedByMe,
      likeCount: update.likeCount,
      commentCount: update.commentCount,
      lastComment: lastComment,
      currentPhotoSlideIndex: 0,
      currentMedia: update.medias.length > 0 ? update.medias[0] : null,
      loading: false,
    });

    try {
      this.refreshComment(this.state.wholeComment);
    } catch(error) {
      console.log("[ProjectPostPanel] onScreenFocused() -> error:", error);
    }
  }

  refreshComments = async () => {
    // this.setState({
    //   loading: true,
    // });

    // var update = this.props.post;
    // var commentCount = await MongoProjectUpdateCommentEngine.getCountByUpdateId(update.docId);
    // var lastComment = await MongoProjectUpdateCommentEngine.getLatestByUpdateId(update.docId);
    // if (lastComment != null) {
    //   var likedByMe = false;
    //   /* if (this.props.signedUser != null) {
    //     likedByMe = await MongoProjectUpdateCommentLikeEngine.isLiked(lastComment.docId, this.props.signedUser.docId);
    //   } */
    //   lastComment.likedByMe = likedByMe;
    // }

    // this.setState({
    //   commentCount: commentCount,
    //   lastComment: lastComment,
    //   commentText: "",
    //   commentHeartCount: 0,
    //   loading: false,
    // });
  }

  refreshComment = (wholeComment) => {
    var words = this.props.post.comment.trim().split(" ");
    var commentWordCount = words.length;
    var comment = "";
    if (wholeComment) {
      comment = this.props.post.comment.trim();
    } else {
      for (var i = 0; i < words.length; i++) {
        if (i >= 50) {
          break;
        }

        if (comment != "") {
          comment += " ";
        }
        comment += words[i];
      }
    }

    this.setState({
      commentWordCount: commentWordCount,
      comment: comment,
    });
  }

  onPressEllipsis = () => {
    this.setState({
      ellipsisPressed: !this.state.ellipsisPressed,
    });
  }

  onPressProject = async () => {
    /* if (this.props.navigation != undefined && this.props.navigation != null) {
      var project = await MongoProjectEngine.getByDocId(this.props.post.projectId);
      if (project != null) {
        this.props.navigation.navigate('ProjectDetails', {
          project: project,
        });
      }
    } */
  }

  onPressDetails = () => {
    this.props.onPressAction();
    this.setState({
      ellipsisPressed: !this.state.ellipsisPressed,
    });
    /* this.setState({
      ellipsisPressed: !this.state.ellipsisPressed,
    }, () => {
      this.props.navigation.navigate('ProjectPostDetails', {
        post: this.props.post,
      });
    }); */
  }

  onPressEdit = () => {
    this.props.onPressAction();
    /* this.setState({
      ellipsisPressed: !this.state.ellipsisPressed,
    }, () => {
      this.props.navigation.navigate('ProjectPost', {
        post: this.props.post,
      });
    }); */
  }

  onPressDelete = async () => {
    this.props.onPressAction();
    /* this.setState({
      ellipsisPressed: !this.state.ellipsisPressed,
      loading: true,
    });

    try {
      await MongoProjectUpdateEngine.delete(this.props.post);

      if (this.props.onDeleted) {
        this.props.onDeleted(this.props.post);
      }
    } catch(error) {
      console.error("[ProjectPostPanel] onPressDelete() -> error: ", error);
    }

    this.setState({
      loading: false,
    }); */
  }

  onPressShare = async () => {
    this.props.onPressAction();
    this.setState({
      ellipsisPressed: !this.state.ellipsisPressed,
    });
    /* this.setState({
      ellipsisPressed: !this.state.ellipsisPressed,
      loading: true,
    });

    try {
      var update = this.props.post;

      // fetch image from url
      var base64 = null;
      var logo = MongoProjectUpdateEngine.getLogoImage(update);
      if (logo != null) {
        var blobConfig = RNFetchBlob.config({
          fileCache: true,
        });

        var resp = await blobConfig.fetch("GET", logo.url);
        var imagePath = resp.path();
        var base64 = await resp.readFile("base64");
        RNFetchBlob.fs.unlink(imagePath);
      }

      Share.open({
        subject: "Update – " + update.projectTitle,
        title: "Update – " + update.projectTitle,
        message: "Enviromates Update\n\nProject: " + update.projectTitle + "\n\n" + update.comment,
        url: base64 == null ? "" : "data:image/png;base64," + base64,
      });
    } catch(err) {
      console.log("[ProjectPostPanel] onShare() -> error: " + err.message);
    }

    this.setState({
      loading: false,
    }); */
  }

  onPressPlay = () => {
    if (this.state.currentMedia == null || this.state.currentMedia.type != "video") {
      return;
    }

    var currentVideoPlayer = this.videoPlayers[this.state.currentPhotoSlideIndex];
    if (currentVideoPlayer != null) {
      if (currentVideoPlayer.isPlaying()) {
        currentVideoPlayer.onControlsStop();
      } else {
        currentVideoPlayer.onControlsReplay();
      }
    }
  }

  onVideoPaused = (paused) => {
    this.setState({
      currentVideoPaused: paused,
    });
  }

  onPhotosSlideLeft = () => {
    /* if (this.photosScroll == null) {
      return;
    }

    if (this.state.currentPhotoSlideIndex == 0 || this.props.post.medias.length == 0) {
      return;
    }

    var interval = win.width - bodyHorizontalMargin * 2;
    var scrollTo = (this.state.currentPhotoSlideIndex - 1) * interval;
    this.photosScroll.scrollTo({
      animated: true,
      x: scrollTo,
      y: 0,
    }); */
  }

  onPhotosSlideRight = () => {
    /* if (this.photosScroll == null) {
      return;
    }

    if (this.state.currentPhotoSlideIndex >= this.props.post.medias.length - 1) {
      return;
    }

    var interval = win.width - bodyHorizontalMargin * 2;
    var scrollTo = (this.state.currentPhotoSlideIndex + 1) * interval;
    this.photosScroll.scrollTo({
      animated: true,
      x: scrollTo,
      y: 0,
    }); */
  }

  onPhotosSlided = (e) => {
    /* let contentOffset = e.nativeEvent.contentOffset;
    let viewSize = e.nativeEvent.layoutMeasurement;

    // Divide the horizontal offset by the width of the view to see which page is visible
    var page = Math.floor(contentOffset.x / viewSize.width);
    var currentMedia = this.props.post.medias.length > page ? this.props.post.medias[page] : null;

    if (this.state.currentPhotoSlideIndex != page) {
      // pause previous video
      if (this.state.currentMedia != null && this.state.currentMedia.type == "video") {
        var currentVideoPlayer = this.videoPlayers[this.state.currentPhotoSlideIndex];
        if (currentVideoPlayer != null) {
          currentVideoPlayer.onControlsStop();
        }
      }
    }

    this.setState({
      currentPhotoSlideIndex: page,
      currentMedia: currentMedia,
    }); */
  }

  onPressLike = async () => {
    this.props.onPressAction();
    /* if (this.props.signedUser == null) {
      return;
    }

    this.setState({
      loading: true,
    });

    var update = this.props.post;
    var likedByMe = await MongoProjectUpdateLikeEngine.isLiked(update.docId, this.props.signedUser.docId);
    if (likedByMe == true) {
      await MongoProjectUpdateLikeEngine.dislike(update.docId, this.props.signedUser.docId);
    } else {
      await MongoProjectUpdateLikeEngine.like(update.projectId, update.docId, this.props.signedUser.docId);
    }

    likedByMe = await MongoProjectUpdateLikeEngine.isLiked(update.docId, this.props.signedUser.docId);

    var likeCount = await MongoProjectUpdateLikeEngine.getCountByUpdateId(update.docId);
    likeCount += await MongoProjectUpdateCommentLikeEngine.getCountByUpdateId(update.docId);
    update.likeCount = likeCount;
    await MongoProjectUpdateEngine.update(update);

    this.setState({
      likeCount: likeCount,
      likedByMe: likedByMe,
      loading: false,
    });

    // callback
    if (this.props.onLiked) {
      this.props.onLiked(this.props.project);
    } */
  }

  onPressIntroCommentCollapse = () => {
    this.setState({
      wholeComment: false,
    });

    this.refreshComment(false);
  }

  onPressIntroCommentExpand = () => {
    this.setState({
      wholeComment: true,
    });

    this.refreshComment(true);
  }

  onPressCommentLike = async (comment) => {
    this.props.onPressAction();
    /* if (this.props.signedUser == null) {
      return;
    }

    this.setState({
      loading: true,
    });

    var likedByMe = comment.likedByMe;
    if (likedByMe == false) {
      var result = await MongoProjectUpdateCommentEngine.like(comment, this.props.signedUser.docId);
      if (result == true) {
        likedByMe = true;
      }
    } else {
      var result = await MongoProjectUpdateCommentEngine.dislike(comment, this.props.signedUser.docId);
      if (result == true) {
        likedByMe = false;
      }
    }

    comment.likedByMe = likedByMe;

    this.setState({
      loading: false,
    }); */
  }

  onMediaSlided = (e) => {
    /* let contentOffset = e.nativeEvent.contentOffset;
    let viewSize = e.nativeEvent.layoutMeasurement;

    // Divide the horizontal offset by the width of the view to see which page is visible
    var page = Math.floor(contentOffset.x / viewSize.width);
    this.setState({
      currentMediaSlideIndex: page,
    }); */
  }

  onPressViewComments = () => {
    this.props.onPressAction();
    /* this.props.navigation.navigate('ProjectPostDetails', {
      post: this.props.post,
    }); */
  }

  onPressCommenting = () => {
    this.props.onPressAction();
    /* if (this.props.signedUser == null) {
      return;
    }

    if (this.commentActionSheet == null) {
      return;
    }

    this.setState({
      commentText: "",
      commentHeartCount: 0,
    }, () => {
      this.commentActionSheet.show();
    }); */
  }

  onCommentUpdated = (value) => {
    /* this.setState({
      commentText: value,
    }); */
  }

  onPressCommentHeart = (heart) => {
    this.props.onPressAction();
    /* var commentHeartCount = 0;
    if (heart.available) {
      commentHeartCount = heart.count;
    }

    this.setState({
      commentHeartCount: commentHeartCount,
    }); */
  }

  onPressCommentSend = async () => {
    this.props.onPressAction();
    /* if (this.props.signedUser == null) {
      return;
    }
    if (this.state.commentText == "") {
      return;
    }
    if (this.commentActionSheet != null) {
      this.commentActionSheet.hide();
    }

    this.setState({
      loading: true,
    });

    try {
      var update = this.props.post;
      var project = await MongoProjectEngine.getByDocId(update.projectId);
      if (project != null) {
        await MongoProjectUpdateCommentEngine.comment(project, update, this.props.signedUser, this.state.commentText, this.state.commentHeartCount);
      }

      if (this.state.commentHeartCount > 0 && this.props.onPledged) {
        this.props.onPledged(project);
      }

      this.setState({
        commentText: "",
        commentHeartCount: 0,
        loading: false,
      });

      await this.refreshComments();
    } catch(error) {
      console.error("[ProjectPostPanel] onPressCommentSend() -> error: ", error.message);

      this.setState({
        loading: false,
      });
    } */
  }

  onPanelSizeUpdated = (event) => {
    var panelWidth = event.nativeEvent.layout.width;
    var mediaWidth = panelWidth;
    var mediaHeight = mediaWidth / mediaAspectRatio;
    this.setState({
      panelWidth: panelWidth,
      mediaWidth: mediaWidth,
      mediaHeight: mediaHeight,
    });
  }

  onPressCrowdfunding = () => {
    this.props.onPressAction();
    //this.setState({
    //  showPledgeModal: true,
    //});
  }

  onPledged = async () => {
    /* this.setState({
      showPledgeModal: false,
      loading: true,
    });

    var update = this.props.post;
    var project = await MongoProjectEngine.getByDocId(update.projectId);

    // pledge
    var pledgedAmount = MongoProjectEngine.getFundedAmountWithDecimals(project, 0);
    this.setState({
      project: project,
      pledgedAmount: pledgedAmount,
      supporterCount: project.supporterCount,
      loading: false,
    }, () => {
      if (this.props.onPledged) {
        this.props.onPledged(project);
      }
    }); */
  }

  onClosePledgeModal = () => {
    this.setState({
      showPledgeModal: false,
    });
  }

  renderMedias = () => {
    var update = this.props.post;
    return (
      <div className="bodyMedia">
        <div className="bodyMediaPhotos">
          { this.props.post.medias.length > 1 ? (
            <Slide>
              { this.props.post.medias.map((media, index) => this.renderMedia(media, index)) }
            </Slide>
          ) : this.props.post.medias.length == 1 ? (
            <div>
              {this.renderMedia(this.props.post.medias[0], 0)}
            </div>
          ) :(
            <div className="bodyMediaPhotosEmpty" style={{width: "100%", height: this.state.mediaHeight}}>
              <FaBan size={50} className="bodyMediaPhotosEmptyIcon"/>
            </div>
          )}
        </div>
        <div className="bodyMediaImageControl" style={{width: "100%", height: this.state.mediaHeight}}>
          <div className="bodyMediaEmptyHeader">
            <Link to="" className="bodyMediaEmptyHeaderUser" onClick={this.onPressProject}>
              { update.projectLogoUrl !== "" ? (
                <div className="bodyMediaEmptyHeaderUserLogo">
                  <img
                    className="bodyMediaImageControlHeaderUserLogoIcon"
                    src={update.projectLogoUrl}
                  />
                </div>
              ) : (
                <div className="bodyMediaImageControHeaderUserLogoEmpty">
                  <FaUserCircle size={30} className="bodyMediaImageControHeaderUserLogoEmptyIcon"/>
                </div>
              )}
              <div className="bodyMediaImageControlHeaderUserName">
                <span className="bodyMediaImageControlHeaderUserNameText">{ update.projectTitle }</span>
              </div>
            </Link>
            <Link to="" className={this.state.ellipsisPressed ? "bodyMediaEmptyHeaderTitleEllipsisPressed" : "bodyMediaEmptyHeaderTitleEllipsis"} onClick={this.onPressEllipsis}>
              <FaEllipsis size={20} className="bodyMediaEmptyHeaderTitleEllipsisIcon"/>
            </Link>
            { this.state.ellipsisPressed &&
              this.renderTitleMenu()
            }
          </div>
          <div className="bodyMediaEmptyContent">
            <div className="bodyMediaEmptyContentLeft">
              { (this.props.post.medias.length > 0 && this.state.currentPhotoSlideIndex > 0) &&
                <Link to="" onClick={this.onPhotosSlideLeft}>
                  <FaAngleLeft size={30} className="bodyMediaEmptyContentCenterSlideIcon"/>
                </Link>
              }
            </div>
            <div className="bodyMediaEmptyContentCenter">
              { (this.state.currentMedia != null && this.state.currentMedia.type == "video") &&
                <Link to="" className="bodyMediaEmptyContentCenterPlay" onClick={this.onPressPlay}>
                  {this.state.currentVideoPaused ? <FaCirclePlay size={50} className="bodyMediaEmptyContentCenterPlayIcon" />
                  : <FaCirclePause size={50} className="bodyMediaEmptyContentCenterPlayIcon" />}
                </Link>
              }
            </div>
            <div className="bodyMediaEmptyContentRight">
              { (this.state.currentPhotoSlideIndex < this.props.post.medias.length - 1) &&
                <Link to="" onClick={this.onPhotosSlideRight}>
                  <FaAngleRight size={30} className="bodyMediaEmptyContentCenterSlideIcon"/>
                </Link>
              }
            </div>
          </div>
          <div className="bodyMediaEmptyFooter">
            <div className="bodyMediaEmptyFooterContent">
              { this.props.post.medias.length > 1 &&
                <div className="bodyMediaEmptyFooterDots">
                  { this.props.post.medias.map((meida, index) => this.renderMediasNavItem(meida, index)) }
                </div>
              }
              <div className="bodyMediaEmptyFooterLeft">
                <div className="bodyMediaEmptyFooterLeftItem">
                  <Link to="" hitSlop={{top: 10, bottom: 10, left: 10, right: 10}} onClick={this.onPressLike}>
                    <FaThumbsUp size={20} className="bodyMediaImageControlFooterLeftItemIcon" />
                  </Link>
                  <span className="bodyMediaImageControlFooterLeftItemText">{ this.state.likeCount }</span>
                </div>
                <div className="bodyMediaEmptyFooterLeftItem">
                  <Link to="" hitSlop={{top: 10, bottom: 10, left: 10, right: 10}} onClick={this.onPressCommenting}>
                    <FaMessage size={20} className="bodyMediaImageControlFooterLeftItemIcon"/>
                  </Link>
                  <span className="bodyMediaImageControlFooterLeftItemText">{ this.state.commentCount }</span>
                </div>
              </div>
              <div className="bodyMediaEmptyFooterCenter"></div>
              <div className="bodyMediaEmptyFooterRight">
                { this.enableCrowdfunding &&
                  <Link to="" hitSlop={{top: 10, bottom: 10, left: 10, right: 10}} onClick={this.onPressCrowdfunding}>
                    <img src={img_crowdfunding}/>
                  </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMedia = (media, index) => {
    if (media.type == "image") {
      return this.renderMediaImage(media);
    }

    return (
      <div className="bodyMediaPhotoSliderPage" style={{width: "100%", height: this.state.mediaHeight}}>
        <Player
          autoPlay={true}
          className="bodyMediaPhotoSliderPageMedia"
          fluid={false}
          height={this.state.mediaWidth / (607 / 1080)}
          muted={false}
          playsInline={true}
          ref={player => {this.videoPlayers[index] = player}}
          src={media.url}
          width={this.state.mediaWidth}
        >
          <BigPlayButton className="bodyVideoBigButton" position="center"/>
          <ControlBar autoHide={true} className="bodyVideoControlBar"/>
        </Player>
       {/*  <VideoPlayer
          info={false}
          onPaused={paused => this.onVideoPaused(paused)}
          overlay={this.props.overlay}
          playButton={false}
          ref={ref => (this.videoPlayers[index] = ref)}
          source={{uri: media.url}}
          className="bodyMediaPhotoSliderPageMedia"
        /> */}
      </div>
    );
  }

  renderMediaImage = (media) => {
    return (
      <div className="bodyMediaPhotoSliderPage" style={{width: "100%", height: this.state.mediaHeight}}>
        <img
          src={media.url}
          className="bodyMediaPhotoSliderPageMedia"
          style={{width: "100%", height: this.state.mediaHeight}}
        />
      </div>
    );
  }

  renderMediasNavItem(media, index) {
    return (
      <div className={this.state.currentPhotoSlideIndex == index ? "bodyMediaPhotoSliderNavigatorActivDot" : "bodyMediaPhotoSliderNavigatorInactivDot"}></div>
    );
  }

  renderTitleMenu = () => {
    var update = this.props.post;
    return (
      <div className="bodyMediaEmptyHeaderTitleMenu">
        <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressDetails}>
          <IoInformationCircleOutline size={20} className="bodyMediaEmptyHeaderTitleMenuItemIcon"/>
          <span className="bodyMediaEmptyHeaderTitleMenuItemText">Open this update</span>
        </Link>
        {/*  (this.props.signedUser != null && this.props.signedUser.docId == update.creatorId) &&
          <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressEdit}>
            <SlPencil size={18} className="bodyMediaEmptyHeaderTitleMenuItemIcon"/>
            <span className="bodyMediaEmptyHeaderTitleMenuItemText">Edit this update</span>
          </Link>
         */}
        { /* (this.props.signedUser != null && (this.props.signedUser.docId == update.creatorId || this.props.signedUser.docId == update.projectCreatorId)) &&
          <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressDelete}>
            <FaTrashCan size={18} className="bodyMediaEmptyHeaderTitleMenuItemIcon"/>
            <span className="bodyMediaEmptyHeaderTitleMenuItemText">Delete this update</span>
          </Link>
         */}
        <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressShare}>
          <MdLaunch size={20} className="bodyMediaEmptyHeaderTitleMenuItemIcon"/>
          <span className="bodyMediaEmptyHeaderTitleMenuItemText">Share external</span>
        </Link>
      </div>
    );
  }

  renderEmptyComment = () => {
    var logoUrl = "";
    var user = this.props.signedUser;
    if (user != null) {
      logoUrl = user.logoUrl;
    }

    return (
      <div className={this.enableCrowdfunding ? "bodyCommentEmpty" : "panelEnd"}>
        
          <div className="bodyCommentLogo">
            <div className="bodyCommentLogo">
              <FaUserCircle size={32} className="bodyCommentLogoEmpty"/>
            </div>
          </div>
        <div className="bodyCommentContent">
          <Link to="" className="bodyCommentCommenting" onClick={this.onPressCommenting}>
            <span className="bodyCommentCommentingText">Comment...</span>
          </Link>
        </div>
      </div>
    );
  }

  renderLastComment = () => {
    var comment = this.state.lastComment;
    if (comment == null) {
      return null;
    }

    var update = this.props.post;
    return (
      <div className="bodyComment">
        { comment.userLogo == "" ? (
          <div className="bodyCommentLogo">
            <FaUserCircle size={32} className="bodyCommentLogoEmpty"/>
          </div>
        ) : (
          <div className="bodyCommentLogo">
            { comment.heartCount > 0 &&
              <FaHeart size={7} className="bodyCommentLogoHeart" style={{left: -5, top: -3, transform:[{rotate: "-45deg"}]}}/>
            }
            { comment.heartCount > 0 &&
              <FaHeart size={12} className="bodyCommentLogoHeart" style={{left: 3, top: -13, transform:[{rotate: "-10deg"}]}}/>
            }
            { comment.heartCount > 0 &&
              <FaHeart size={9} className="bodyCommentLogoHeart" style={{left: 17, top: -10, transform:[{rotate: "15deg"}]}}/>
            }
            <img
              className="bodyCommentLogoIcon"
              src={comment.creatorLogoUrl}
            />
          </div>
        )}
        <div className="bodyCommentContent">
          <div className="bodyCommentContentRow">
            <div className="bodyCommentTitle">
              <span className="bodyCommentTitleText">{comment.creatorName}</span>
            </div>
            { comment.creatorId == update.creatorId &&
              <div className="bodyCommentCreator">
                <span className="bodyCommentCreatorText">CREATOR</span>
              </div>
            }
          </div>
          <div className="bodyCommentContentRow" style={{marginTop: 5}}>
            <div className="bodyCommentBody">
              <span className="bodyCommentBodyText">{comment.text}</span>
            </div>
            <Link to="" className="bodyCommentLike" onClick={() => this.onPressCommentLike(comment)}>
              <AiOutlineLike size={12} className="bodyCommentLikeIcon"/>
            </Link>
            {/*  this.props.signedUser != null && this.props.signedUser.docId != comment.creatorId &&
              <Link to="" className="bodyCommentLike} onClick={() => this.onPressCommentLike(comment)}>
                { comment.likedByMe == true ? (
                  <AiOutlineLike size={12} className="bodyCommentLikeIcon}/>
                ) : (
                  <AiOutlineLike size={12} className="bodyCommentLikeIcon}/>
                )}
              </Link>
             */}
          </div>
          <div className="bodyCommentContentRow" style={{justifyContent: "flex-start", marginTop: 10}}>
            <Link to="" className="bodyCommentReply" onClick={this.onPressViewComments}>
              <span className="bodyCommentTimeText">View all { this.state.commentCount } comments</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderCommentEdit = () => {
    var logoUrl = "";
    var user = this.props.signedUser;
    if (user != null) {
      logoUrl = user.logoUrl;
    }

    return (
      <div
        onLayout={this.onCommentEditLyout}
        className="commentActionSheet"
      >
        <div className="commentActionSheetRow">
          <div className="commentActionSheetHearts">
            { this.state.availableHearts.map((item, index) => this.renderCommentEditHeart(item)) }
          </div>
        </div>
        <div className="commentActionSheetRow">
          { logoUrl == "" ? (
            <FaUserCircle size={30} className="commentActionSheetLogoEmpty"/>
          ) : (
            <div className="commentActionSheetLogo">
              <img
                className="commentActionSheetLogoIcon"
                src={logoUrl}
              />
            </div>
          )}
          <div className="commentActionSheetInput">
            <input type="text"
              autoCapitalize='none'
              autoComplete="off"
              autoCorrect={false}
              autoFocus={true}
              multiline={true}
              onChangeText={this.onCommentUpdated}
              placeholder={'add a comment'}
              placeholderTextColor='#838383'
              className="commentActionSheetInputText"
              value={this.state.commentText}
            />
            { this.state.commentHeartCount > 0 &&
              <div className="commentActionSheetInputHeart">
                <FaHeart size={24} className="commentActionSheetHeartIcon"/>
                <span className="commentActionSheetHeartText">{ this.state.commentHeartCount }</span>
              </div>
            }
            { this.state.commentText != "" &&
              <Link to="" className="commentActionSheetInputSend" onClick={this.onPressCommentSend}>
                <span className="commentActionSheetInputSendText">Send</span>
              </Link>
            }
          </div>
        </div>
      </div>
    );
  }

  renderCommentEditHeart = (item) => {
    if (item.available != true) {
      return null;
    }

    return (
      <Link to="" className="commentActionSheetHeart" onClick={() => this.onPressCommentHeart(item)}>
        <FaHeart size={24} className={item.available ? "commentActionSheetHeartIcon" : "commentActionSheetHeartIconDisabled"}/>
        <span className="commentActionSheetHeartText">{ item.count }</span>
      </Link>
    );
  }

  render() {
    var update = this.props.post;
    this.enableCrowdfunding = update.additionalFunding != null && update.additionalFunding.target > 0;
    var fundedPercent = MongoProjectUpdateEngine.getFundedPercentWithDecimals(update, 1);
    var currencyCode = "GBP";
    if (update.additionalFunding != null) {
      currencyCode = update.additionalFunding.currencyCode;
    }
    var currency = Utility.getCurrencyByCode(currencyCode);
    return (
      <div
        onLayout={this.onPanelSizeUpdated}
        className="postPanel"
      >
        <div className="bodyMedia">
          { this.renderMedias() }
        </div>
        { this.enableCrowdfunding &&
          <div className="bodyCrowdfund">
            <div className="bodyCrowdfundProgress">
              <div style={{width: `${fundedPercent}%`, height: "100%", backgroundColor: "#72C500"}}></div>
            </div>
            <div className="bodyCrowdfundRow">
              <span className="txtCrowdfund">{fundedPercent}% funded</span>
              <div style={{flex: 1}}></div>
              <span className="txtCrowdfund">Target</span>
              <img
                className="imgCrowdfundHeart"
                src={icon_heart_full}
              />
              <span className="txtCrowdfundHeart">
                { Formatter.integerToEasyString(update.additionalFunding.target / HEART_VALUE) }
              </span>
            </div>
            <div className="bodyCrowdfundRow">
              <div style={{flex: 1}}></div>
              <span className="txtCrowdfundCurrency">
                { currency.symbol }
                { (Number(update.additionalFunding.target) / 100).toFixed(2) }
              </span>
            </div>
          </div>
        }
        { this.props.post.comment != "" &&
          <div className="bodyInfo">
            <span className="bodySummaryText">
              { this.state.comment + " " }
              { (this.state.commentWordCount > 50 && !this.state.wholeComment) &&
                <span className="bodySummaryTextContinue" onClick={this.onPressIntroCommentExpand}>Continue reading</span>
              }
              { (this.state.commentWordCount > 50 && this.state.wholeComment) &&
                <span className="bodySummaryTextContinue" onClick={this.onPressIntroCommentCollapse}>Show less</span>
              }
            </span>
          </div>
        }
        <div className="bodyInfo" style={{paddingTop: 10, paddingBottom: 10}}>
          <span className="bodyInfoPost">
            posted { moment(update.timestamp).format('DD.MM.YYYY') }
            { update.creatorName != "" && ", by " + update.creatorName }
          </span>
        </div>
        { this.state.lastComment != null  && this.renderLastComment() }
        {/* this.renderEmptyComment() */}
        { this.enableCrowdfunding &&
          <Link to="" className="btnCrowdfund" onClick={this.onPressCrowdfunding}>
            <span className="btnCrowdfundText">Micro crowdfound this update</span>
          </Link>
        }

        {/* <ActionSheet
          ref={(ref) => this.commentActionSheet = ref}
        >
          { this.renderCommentEdit() }
        </ActionSheet> */}

        {/*  this.state.showPledgeModal &&
          <ProjectUpdatePledgeModal
            modalVisible={this.state.showPledgeModal}
            onCancel={this.onClosePledgeModal}
            onPledge={this.onPledged}
            update={update}
            user={this.props.signedUser}
          />
         */}

        { /* this.state.loading &&
          <div style={GlobalStyles.spinner}>
            <ActivityIndicator color="#72C500" size='large'/>
          </div>
         */}
      </div>
    );
  }
}

export default ProjectPostPanel;
