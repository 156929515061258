import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faFileLines, faMessage, faTrashCan, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faAngleLeft, faAngleRight, faBan, faCircleInfo, faCirclePause, faCirclePlay, faEllipsis, faThumbsUp as faThumbsUpSolid, faUnlink, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import {FaRetweet, FaUserCircle} from "react-icons/fa";
import {IoInformationCircleOutline, } from "react-icons/io5"
import {SlPencil, SlPin} from "react-icons/sl";
import {MdLaunch} from "react-icons/md";
import {GoCopy} from "react-icons/go";


import MongoProject from '../../../../engine/mongodb/project';
import MongoProjectDislikeEngine from '../../../../engine/mongodb/projectdislikes';
import MongoProjectEngine from '../../../../engine/mongodb/projects';
import MongoProjectFollowEngine from '../../../../engine/mongodb/projectfollows';
import MongoProjectLikeEngine from '../../../../engine/mongodb/projectlikes';
import MongoProjectPinEngine from '../../../../engine/mongodb/projectpins';
import MongoTeam from '../../../../engine/mongodb/team';
import MongoTeamEngine from '../../../../engine/mongodb/teams';
import MongoUserEngine from '../../../../engine/mongodb/users';

import { Player, BigPlayButton, ControlBar } from "video-react";
import "video-react/dist/video-react.css";

import "../../../../App.css";
import "./styles.css";
import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Popup from 'react-popup';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const bodyHorizontalMargin = 10;
const teamMemberIconWidth = 30;
const teamMemberRightMargin = 5;
const mediaAspectRatio = 353 / 238;

class ProjectPanel extends Component {
  constructor(props) {
    super(props);
    console.log('this.props.userBlockWidth', this.props.width);

    var panelWidth = this.props.width;
    var mediaWidth = panelWidth;
    var mediaHeight = mediaWidth / mediaAspectRatio;

    var project = this.props.project;

    this.state = {
      project: project,
      preparedPercent: MongoProjectEngine.getPreparedPercent(project),

      likeCount: 0,
      likedByMe: false,

      pinnedByMe: false,
      pinDecided: false,

      followedByMe: false,

      commentCount: 0,
      postCount: 0,
      followerCount: 0,

      pledgedAmount: 0,

      supporterCount: 0,

      ellipsisPressed: false,

      currentPhotoSlideIndex: 0,
      currentMedia: null,

      currentVideoPaused: true,

      panelWidth: panelWidth,

      mediaWidth: mediaWidth,
      mediaHeight: mediaHeight,

      showPledgeModal: false,

      loading: false,
      isShowToast: false,
      toastMsg: '',
    };

    this.videoPlayers = [];
    this.photosScroll = null;
  }

  componentDidMount = async () => {
    this.onScreenFocused();
  }

  componentDidUpdate = async (prevProps) => {
    // refresh
    if (prevProps.project.docId != this.props.project.docId) {
      this.videoPlayers = [];
      this.photosScroll = null;
      this.onScreenFocused();
      return;
    }

    // update signed user changes
    // var userName = "";
    // var logoUrl = "";
    // if (MongoUserEngine.isMongoUser(this.props.signedUser)) {
    //   userName = MongoUserEngine.getFriendlyUserName(this.props.signedUser);
    //   logoUrl = this.props.signedUser.logoUrl;
    // }

    // var project = this.props.project;

    // if (this.props.signedUser.docId == project.creatorId) {
    //   if (userName != project.creatorName
    //       || logoUrl != project.creatorLogoUrl) {
    //     project.creatorName = userName;
    //     project.creatorLogoUrl = logoUrl;

    //     this.setState({
    //       user: user,
    //     });
    //   }
    // }

    // prepared percent
    //var preparedPercent = MongoProjectEngine.getPreparedPercent(project);
    //this.setState({
    //  preparedPercent: preparedPercent,
    //});
  }

  onScreenFocused = async () => {
    try {
      this.setState({
        loading: true,
      });

      var project = this.props.project;

      // like
      var likeCount = project.likeCount;
      var likedByMe = false;
      // if (this.props.signedUser != null) {
        // likedByMe = await MongoProjectLikeEngine.isLiked(project.docId, this.props.signedUser.docId);
      //  likedByMe = false; // frontend implementation
      // }

      // pin
      /*
      var pinnedByMe = false;
      if (this.props.signedUser != null) {
        pinnedByMe = await MongoProjectPinEngine.isPinned(project.docId, this.props.signedUser.docId);
      }
      */

      // follow
      var followedByMe = false;
      // if (this.props.signedUser != null) {
      //   followedByMe = await MongoProjectFollowEngine.isFollowed(project.docId, this.props.signedUser.docId);
      // }

      // comment
      var commentCount = project.commentCount;

      // post
      var postCount = project.postCount;

      //followerCounting
      var followerCount = project.followCount;

      // pledge
      var pledgedAmount = MongoProjectEngine.getFundedAmountWithDecimals(project, 0);
      var supporterCount = project.supporterCount;

      this.setState({
        project: project,

        likeCount: likeCount,
        likedByMe: likedByMe,

        //pinnedByMe: pinnedByMe,

        commentCount: commentCount,
        postCount: postCount,
        followerCount: followerCount,
        supporterCount: supporterCount,

        pledgedAmount: pledgedAmount,

        currentPhotoSlideIndex: 0,
        currentMedia: project.medias.length > 0 ? project.medias[0] : null,

        loading: false,
      });
    } catch(error) {
      console.log("[ProjectPanel] onScreenFocused() -> error: ", error);
      this.setState({
        loading: false,
      });
    }
  }

  onBlurred = () => {
    this.videoPlayers.forEach(videoPlayer => {
      if (videoPlayer == null) {
        return;
      }

      videoPlayer.onControlsStop();
    });
  }

  onPressEmpty = () => {
    this.setState({
      ellipsisPressed: false,
    });
  }

  onPressTitle = () => {
    this.onDetails();
  }

  onDetails = () => {
    if (this.props.onDetails) {
      this.props.onDetails();
      return;
    }

    // this.props.navigation.navigate('ProjectDetails', {
    //   project: this.state.project,
    // });
  }

  onPressCreator = async () => {
    var user = await MongoUserEngine.getByDocId(this.state.project.creatorId);
    if (user == null) {
       return;
    }

    const userLink = MongoUserEngine.getUserPersonalLink(user);

    window.location.href = userLink;
    return;

    // if (this.props.signedUser == null) {
    //   this.showJoinPopup();
    //   return;
    // }

    // var user = await MongoUserEngine.getByDocId(this.state.project.creatorId);
    // if (user == null) {
    //   return;
    // }

    // this.props.navigation.navigate('ProfileEnviromate', {user: user});
  }

  showJoinPopup = () => {
    Popup.create({
      title: 'Alert',
      content: 'Please join Enviromates to access this feature',
      buttons: {
        left: ['cancel'],
        right: [{
          text: 'Join',
          action: () => this.gotoLogin(),
        }]
      }
    });
  }

  onPressEllipsis = async () => {
    if (this.state.ellipsisPressed == true) {
      this.setState({
        ellipsisPressed: !this.state.ellipsisPressed,
      });

      return;
    }

    this.setState({
      loading: false,
    });

    var pinnedByMe = this.state.pinnedByMe;
    // if (this.props.signedUser != null) {
    //   pinnedByMe = await MongoProjectPinEngine.isPinned(this.state.project.docId, this.props.signedUser.docId);
    // }

    this.setState({
      pinnedByMe: pinnedByMe,
      pinDecided: true,
      ellipsisPressed: !this.state.ellipsisPressed,
      loading: false,
    });
  }
  
  onPressClone = () => {
    this.props.onPressAction();

    // disabled in frontend
    // this.setState({
    //   ellipsisPressed: !this.state.ellipsisPressed,
    // });

    // if (this.props.signedUser == null) {
    //   this.showJoinPopup();

    //   return;
    // }

    // var project = MongoProjectEngine.clone(this.state.project);
    // project.creatorId = this.props.signedUser.docId;
    // project.creatorName = MongoUserEngine.getFriendlyUserName(this.props.signedUser);
    // project.creatorLogoUrl = this.props.signedUser.logoUrl;

    // this.props.navigation.navigate('ProjectSetup', {
    //   project: project,
    // });
  }

  onPressDelete = async () => {
    this.props.onPressAction();

    // disabled in frontend
    // this.setState({
    //   ellipsisPressed: !this.state.ellipsisPressed,
    //   loading: true,
    // });

    // if (!MongoProjectEngine.isAdministratorByUserId(this.state.project, this.props.signedUser.docId)) {
    //   return;
    // }

    // await MongoProjectEngine.delete(this.state.project);

    // this.setState({
    //   loading: false,
    // }, () => {
    //   if (this.props.onDeleted != undefined) {
    //     this.props.onDeleted();
    //   }
    // });
  }

  onPressDetails = () => {
    this.setState({
      ellipsisPressed: false,
    });

    this.onDetails();
  }

  onPressEdit = () => {
    this.props.onPressAction();

    // disabled in frontend
    // this.setState({
    //   ellipsisPressed: !this.state.ellipsisPressed,
    // });

    // this.props.navigation.navigate('ProjectSetup', {
    //   project: this.state.project,
    // });
  }

  onPressPin = async () => {
    this.props.onPressAction();

    // disabled in frontend
    // this.setState({
    //   ellipsisPressed: !this.state.ellipsisPressed,
    // });

    // if (this.props.signedUser == null) {
    //   this.showJoinPopup();

    //   return;
    // }

    // this.setState({
    //   loading: true,
    // });

    // var pinnedByMe = this.state.pinnedByMe;
    // if (pinnedByMe) {
    //   await MongoProjectPinEngine.unpin(this.state.project.docId, this.props.signedUser.docId);
    // } else {
    //   await MongoProjectPinEngine.pin(this.state.project.docId, this.props.signedUser.docId);
    // }

    // pinnedByMe = await MongoProjectPinEngine.isPinned(this.state.project.docId, this.props.signedUser.docId);

    // this.setState({
    //   pinnedByMe: pinnedByMe,
    //   loading: false,
    // });

    // var message = "You have successfully pinned " + this.state.project.title;
    // if (!pinnedByMe) {
    //   message = "You have successfully unpinned " + this.state.project.title;
    // }

    // this.setState({
    //   isShowToast: true,
    //   toastMsg: message,
    // });

    // if (this.props.onPinned) {
    //   this.props.onPinned(this.state.project, pinnedByMe);
    // }
  }

  onPressPost = () => {
    this.props.onPressAction();

    // disabled in frontend
    // this.setState({
    //   ellipsisPressed: !this.state.ellipsisPressed,
    // });

    // if (this.props.preview == true || this.props.signedUser == null) {
    //   return;
    // }

    // if (MongoProjectEngine.isTeamByUserId(this.state.project, this.props.signedUser.docId) != true) {
    //   this.props.navigation.navigate('ProjectDetails', {
    //     project: this.state.project,
    //     tab: "updates",
    //   });

    //   return;
    // }

    // this.props.navigation.navigate('ProjectPost', {project: this.state.project});
  }

  onPressShare = async () => {
    this.props.onPressAction();

    // this.setState({
    //   ellipsisPressed: !this.state.ellipsisPressed,
    // }, async () => {
    //   this.setState({
    //     loading: true,
    //   });

    //   try {
    //     // fetch image from url
    //     var base64 = null;

    //     // var logo = MongoProjectEngine.getLogoImage(this.state.project);
    //     // if (logo != null) {
    //     //   var blobConfig = RNFetchBlob.config({
    //     //     fileCache: true,
    //     //   });

    //     //   var resp = await blobConfig.fetch("GET", logo.url);
    //     //   var imagePath = resp.path();
    //     //   var base64 = await resp.readFile("base64");
    //     //   RNFetchBlob.fs.unlink(imagePath);
    //     // }

    //     // snapshot
    //     try {
    //       var captureRes = await captureRef(this.refs.viewShot, {format: 'png', quality: 0.1});
    //       base64 = await RNFetchBlob.fs.readFile(captureRes, 'base64');
    //     } catch(e) {
    //       console.log("[ProjectPanel] onPressShare() -> error: " + err.message);
    //     }

    //     var title = "Support my eco project and join me on my journey to help repair our planet!";
    //     var message = title;
    //     message += "\n\nEnviromates Project: " + this.state.project.title;
    //     message += "\n\nSummary:";
    //     message += "\n" + this.state.project.summary;
    //     Share.open({
    //       subject: title,
    //       title: title,
    //       message: message,
    //       url: base64 == null ? "" : "data:image/png;base64," + base64,
    //     });
    //   } catch(err) {
    //     console.log("[ProjectPanel] onPressShare() -> error: ", err);
    //   }

    //   this.setState({
    //     loading: false,
    //   });
    // });
  }

  onPressSummary = () => {
    this.props.onPressAction();
  }

  onPost = () => {
    if (this.props.onPost) {
      this.props.onPost(this.state.project);
    }
  }

  onProfile = async () => {
    if (this.props.onProfile) {
      await this.props.onProfile(this.state.project);
    }
  }

  onPressPlay = () => {
    if (this.state.currentMedia == null || this.state.currentMedia.type != "video") {
      return;
    }

    var currentVideoPlayer = this.videoPlayers[this.state.currentPhotoSlideIndex];
    if (currentVideoPlayer != null) {
      if (currentVideoPlayer.isPlaying()) {
        currentVideoPlayer.onControlsStop();
      } else {
        currentVideoPlayer.onControlsReplay();
      }
    }
  }

  onVideoPaused = (paused) => {
    this.setState({
      currentVideoPaused: paused,
    })
  }

  onPhotosSlideLeft = () => {
    if (this.photosScroll == null) {
      return;
    }

    if (this.state.currentPhotoSlideIndex == 0 || this.state.project.medias.length == 0) {
      return;
    }

    var interval = 300 - bodyHorizontalMargin * 2;
    var scrollTo = (this.state.currentPhotoSlideIndex - 1) * interval;

    this.photosScroll.scrollTo({
      animated: true,
      x: scrollTo,
      y: 0,
    });
  }

  onPhotosSlideRight = () => {
    if (this.photosScroll == null) {
      return;
    }

    if (this.state.currentPhotoSlideIndex >= this.state.project.medias.length - 1) {
      return;
    }

    var interval = window.innerWidth - bodyHorizontalMargin * 2;
    var scrollTo = (this.state.currentPhotoSlideIndex + 1) * interval;
    this.photosScroll.scrollTo({
      animated: true,
      x: scrollTo,
      y: 0,
    });
  }

  onPhotosSlided = (e) => {
    let contentOffset = e.nativeEvent.contentOffset;
    let viewSize = e.nativeEvent.layoutMeasurement;

    // Divide the horizontal offset by the width of the view to see which page is visible
    var page = Math.floor(contentOffset.x / viewSize.width);
    var currentMedia = this.state.project.medias.length > page ? this.state.project.medias[page] : null;

    if (this.state.currentPhotoSlideIndex != page) {
      // pause previous video
      if (this.state.currentMedia != null && this.state.currentMedia.type == "video") {
        var currentVideoPlayer = this.videoPlayers[this.state.currentPhotoSlideIndex];
        if (currentVideoPlayer != null) {
          currentVideoPlayer.onControlsStop();
        }
      }
    }

    this.setState({
      currentPhotoSlideIndex: page,
      currentMedia: currentMedia,
    });
  }

  onPressLike = async () => {
    this.props.onPressAction();

    // disabled in frontend
    // if (this.props.preview == true) {
    //   return;
    // }

    // if (this.props.signedUser == null) {
    //   this.showJoinPopup();
    //   return;
    // }

    // this.setState({
    //   loading: true,
    // });

    // var project = this.state.project;
    // var likedByMe = await MongoProjectLikeEngine.isLiked(project.docId, this.props.signedUser.docId);
    // if (likedByMe == true) {
    //   await MongoProjectLikeEngine.dislike(project, this.props.signedUser.docId);
    // } else {
    //   await MongoProjectLikeEngine.like(project, this.props.signedUser.docId);
    // }

    // likedByMe = await MongoProjectLikeEngine.isLiked(this.state.project.docId, this.props.signedUser.docId);
    // this.setState({
    //   likeCount: project.likeCount,
    //   likedByMe: likedByMe,
    //   loading: false,
    // });

    // // callback
    // if (this.props.onLiked) {
    //   this.props.onLiked(this.state.project);
    // }
  }

  onPressComment = () => {
    this.props.onPressAction();
    // disabled in frontend
    // if (this.props.preview == true) {
    //   return;
    // }

    // if (this.props.signedUser == null) {
    //   this.showJoinPopup();

    //   return;
    // }

    // if (this.props.onCommenting == undefined || this.props.onCommenting == null) {
    //   this.props.navigation.navigate('ProjectDetails', {
    //     project: this.state.project,
    //     startCommenting: true,
    //   });

    //   return;
    // }

    // this.props.onCommenting(this.state.project, this.onCommentUpdated);
  }

  onCommentUpdated = async () => {
    this.setState({
      commentCount: this.state.project.commentCount,
    });
  }

  onPressUpdate = () => {
    this.props.onPressAction();
    /*
    if (this.props.preview == true || this.props.signedUser == null) {
      return;
    }

    if (MongoProjectEngine.isTeamByUserId(this.state.project, this.props.signedUser.docId) != true) {
      this.props.navigation.navigate('ProjectDetails', {
        project: this.state.project,
        tab: "updates",
      });

      return;
    }

    this.props.navigation.navigate('ProjectPost', {project: this.state.project});
    */

    // disabled in frontend
    // this.props.navigation.navigate('ProjectDetails', {
    //   project: this.state.project,
    //   tab: "updates",
    // });
  }

  onPressFollow = async () => {
    // disabled in frontend
    // if (this.props.preview == true) {
    //   return;
    // }

    // if (this.props.signedUser == null) {
    //   this.showJoinPopup();
    //   return;
    // }

    // this.setState({
    //   loading: true,
    // });

    // var followedByMe = this.state.followedByMe;
    // if (followedByMe == true) {
    //   if (await MongoProjectFollowEngine.unfollow(this.state.project, this.props.signedUser.docId) == false) {
    //     return;
    //   }
      
    //   this.setState({
    //     isShowToast: true,
    //     toastMsg: "You have successfully unfollowed " + this.state.project.title,
    //   });
    // } else {
    //   if (await MongoProjectFollowEngine.follow(this.state.project, this.props.signedUser.docId) == false) {
    //     return;
    //   }

    //   this.setState({
    //     isShowToast: true,
    //     toastMsg: "You have successfully followed " + this.state.project.title,
    //   });
    // }

    // followedByMe = await MongoProjectFollowEngine.isFollowed(this.state.project.docId, this.props.signedUser.docId);
    // this.setState({
    //   followedByMe: followedByMe,
    //   loading: false,
    // });
  }

  onPressSetup = () => {
    // disabled in frontend
    // this.props.navigation.navigate('ProjectSetup', {
    //   project: this.state.project,
    // });
  }

  onPressMember = async (member) => {
    var user = await MongoUserEngine.getByDocId(member.memberId);
    if (user == null) {
       return;
    }

    const userLink = MongoUserEngine.getUserPersonalLink(user);

    window.location.href = userLink;
    return;
    // disabled in frontend
    // this.setState({
    //   loading: true,
    // });

    // var user = await MongoUserEngine.getByDocId(member.memberId);

    // this.setState({
    //   loading: false,
    // });

    // if (user != null) {
    //   this.props.navigation.navigate('ProfileEnviromate', {user: user});
    // }
  }

  onPressPledge = (e) => {
    e.preventDefault();
    this.props.onPressAction();
    // disabled in frontend
    // if (this.props.preview == true) {
    //   return;
    // }

    // if (this.props.signedUser == null) {
    //   this.showJoinPopup();

    //   return;
    // }

    // this.setState({
    //   showPledgeModal: true,
    // });
  }

  gotoLogin = () => {
    // disabled in frontend
    // try {
    //   var currentRoute = "";
    //   var state = this.props.navigation.getState();
    //   if (state != null) {
    //     currentRoute = state.routeNames[state.index];
    //   }

    //   this.props.navigation.navigate('UserLoginWith', { toWhat: currentRoute });
    // } catch(error) {
    //   console.error("[ProjectPanel] gotoLogin() -> error: ", error.message);
    // }
  }

  onPledged = async () => {
    // disabled in frontend
    // this.setState({
    //   showPledgeModal: false,
    //   loading: true,
    // });

    // var project = await MongoProjectEngine.getByDocId(this.state.project.docId);

    // // pledge
    // var pledgedAmount = MongoProjectEngine.getFundedAmountWithDecimals(project, 0);
    // this.setState({
    //   project: project,
    //   pledgedAmount: pledgedAmount,
    //   supporterCount: project.supporterCount,
    //   loading: false,
    // }, () => {
    //   if (this.props.onPledged) {
    //     this.props.onPledged(project);
    //   }
    // });
    
  }

  onClosePledgeModal = () => {
    // disabled frontend
    // this.setState({
    //   showPledgeModal: false,
    // });
  }

  onPressDislike = async () => {
    // disabled in frontned
    // this.setState({
    //   ellipsisPressed: !this.state.ellipsisPressed,
    // });

    // if (this.props.isEnviromateProject != true) {
    //   return;
    // }

    // if (this.props.signedUser == null) {
    //   this.showJoinPopup();

    //   return;
    // }

    // this.setState({
    //   loading: true,
    // });

    // try {
    //   await MongoProjectDislikeEngine.dislike(this.props.signedUser, this.state.project);

    //   this.setState({
    //     loading: false,
    //   });

    //   if (this.props.onProjectDisliked) {
    //     this.props.onProjectDisliked(this.state.project);
    //   }
    // } catch(error) {
    //   console.error("[ProjectPanel] onPressDislike() -> error: ", error.message);
    //   this.setState({
    //     loading: false,
    //   });
    // }
  }

  handleToastClose = () => {
    this.setState({
      isShowToast: false,
      toastMsg: '',
    });
  }

  onPanelSizeUpdated = (event) => {
    /* var panelWidth = event.nativeEvent.layout.width;
    console.log('onPanelSizeUpdated', panelWidth);
    var mediaWidth = panelWidth;
    var mediaHeight = mediaWidth / mediaAspectRatio;
    this.setState({
      panelWidth: panelWidth,
      mediaWidth: mediaWidth,
      mediaHeight: mediaHeight,
    }); */
  }

  renderMedias = () => {
    //return null;
    var project = this.state.project;
    return (
      <div className="bodyMedia">
        <div className="bodyMediaPhotos">
          { project.medias.length > 0 ? (
            <Slide>
              {/* <ScrollView
                contentContainerStyle = {styles.bodyMediaPhotoSliderContent}
                horizontal={true}
                onMomentumScrollEnd={this.onPhotosSlided}
                pagingEnabled={true}
                ref={ref => (this.photosScroll = ref)}
                showsHorizontalScrollIndicator={false}
                style={{...styles.bodyMediaPhotoSlider, width: this.state.mediaWidth, height: this.state.mediaHeight}}
              >
                { project.medias.map((media, index) => this.renderMedia(media, index)) }
              </ScrollView> */}
              { project.medias.map((media, index) => this.renderMedia(media, index)) }
            </Slide>
          ) : (
            <div className="bodyMediaPhotosEmpty" style={{width: "100%", height: this.state.mediaHeight}}>
              <FontAwesomeIcon icon={ faBan } size={50} className="bodyMediaPhotosEmptyIcon" style={{width: 50, height: 50}} />
            </div>
          )}
        </div>
        <div className="bodyMediaImageControl" style={{width: "100%", height: this.state.mediaHeight}}>
          <div className="bodyMediaEmptyHeader">
            <div className="bodyMediaEmptyHeaderTitle">
              <Link to="" className="bodyMediaImageControlHeaderTitle" onClick={this.onPressTitle}>
                <span className="bodyMediaImageControlHeaderTitleText">{project.title}</span>
              </Link>
              <Link to="" className={this.state.ellipsisPressed ? "bodyMediaEmptyHeaderTitleEllipsisPressed" : "bodyMediaEmptyHeaderTitleEllipsis"} onClick={this.onPressEllipsis}>
                <FontAwesomeIcon icon={ faEllipsis } size={20} color="white" />
              </Link>
              { this.state.ellipsisPressed &&
                this.renderTitleMenu()
              }
            </div>
            <Link to="" className="bodyMediaEmptyHeaderUser" onClick={this.onPressCreator}>
              { project.creatorLogoUrl != "" ? (
                <div className="bodyMediaEmptyHeaderUserLogo">
                  <img
                    className="bodyMediaImageControlHeaderUserLogoIcon"
                    src={project.creatorLogoUrl}
                  />
                </div>
              ) : (
                <FaUserCircle
                    style={{
                        //borderColor: "white",
                        //borderRadius: 15,
                        //borderWidth: 1,
                        //borderStyle: 'solid',
                        //color: "#D8D8D8",
                        height: 30,
                        width: 30,
                    }}
                />
              )}
              <div className="bodyMediaImageControlHeaderUserName">
                <span className="bodyMediaImageControlHeaderUserNameText">by { project.creatorName }</span>
              </div>
            </Link>
          </div>
          {/* <div className="bodyMediaEmptyContent">
            <div className="bodyMediaEmptyContentLeft">
              { (project.medias.length > 0 && this.state.currentPhotoSlideIndex > 0) &&
                <Link to="" onClick={this.onPhotosSlideLeft}>
                  <FontAwesomeIcon icon={faAngleLeft} size={30} color='white' />
                </Link>
              }
            </div>
            <div className="bodyMediaEmptyContentCenter">
              { (this.state.currentMedia != null && this.state.currentMedia.type == "video") &&
                <Link to="" className="bodyMediaEmptyContentCenterPlay" onClick={this.onPressPlay}>
                  <FontAwesomeIcon
                    icon={this.state.currentVideoPaused ? faCirclePlay : faCirclePause}
                    size={50}
                    style={{color: (project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) ? "#72C500" : 'darkgrey'}}
                  />
                </Link>
              }
            </div>
            <div className="bodyMediaEmptyContentRight">
              { (this.state.currentPhotoSlideIndex < project.medias.length - 1) &&
                <Link to="" onClick={this.onPhotosSlideRight}>
                  <FontAwesomeIcon icon={faAngleRight} size={30} color='white' />
                </Link>
              }
            </div>
          </div> */}
          <div className="bodyMediaEmptyFooter">
            <div className="bodyMediaEmptyFooterContent">
              {/* { project.medias.length > 1 &&
                <div className="bodyMediaEmptyFooterDots">
                  { project.medias.map((meida, index) => this.renderMediasNavItem(meida, index)) }
                </div>
              } */}
              <div className="bodyMediaEmptyFooterLeft">
                { (project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) &&
                  <div className="bodyMediaEmptyFooterLeftItem">
                    <Link to="" onClick={this.onPressLike}>
                      <FontAwesomeIcon
                        icon={ this.state.likedByMe == true ? faThumbsUpSolid : faThumbsUp }
                        size={20}
                        color='white'
                      />
                    </Link>
                    <span className="bodyMediaImageControlFooterLeftItemText">{ this.state.likeCount }</span>
                  </div>
                }
                { (project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) &&
                  <div className="bodyMediaEmptyFooterLeftItem">
                    <Link to="" onClick={this.onPressComment}>
                      <FontAwesomeIcon icon={ faMessage } size={20} color='white' />
                    </Link>
                    <span className="bodyMediaImageControlFooterLeftItemText">{ this.state.commentCount }</span>
                  </div>
                }
              </div>
              <div className="bodyMediaEmptyFooterCenter"></div>
              <div className="bodyMediaEmptyFooterRight">
                { this.renderStatus() }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMedia = (media, index) => {
    console.log(media.type);
    if (media.type == "image") {
      return this.renderMediaImage(media);
    }

    var project = this.state.project;
    return (
      <div className="bodyMediaPhotoSliderPage"  style={{width: "100%", height: this.state.mediaHeight}}>
        <Player
          autoPlay={true}
          className="bodyMediaPhotoSliderPageMedia"
          fluid={false}
          height={this.state.mediaWidth / (607 / 1080)}
          muted={false}
          playsInline={true}
          ref={player => {this.videoPlayers[index] = player}}
          src={media.url}
          width={this.state.mediaWidth}
        >
          <BigPlayButton className="bodyVideoBigButton" position="center"/>
          <ControlBar autoHide={true} className="bodyVideoControlBar"/>
        </Player>
        {/* <VideoPlayer
          info={false}
          key={index}
          grayOut={(project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) ? false : true}
          onPaused={paused => this.onVideoPaused(paused)}
          overlay={this.props.overlay}
          playButton={false}
          project={project}
          ref={ref => (this.videoPlayers[index] = ref)}
          source={{uri: media.url}}
          className="bodyMediaPhotoSliderPageMedia"
          user={this.props.signedUser}
        /> */}
      </div>
    );
  }

  renderMediaImage = (media) => {
    var project = this.state.project;
    const mediaHeight = this.state.mediaHeight;
    const mediaWidth = this.state.mediaWidth;
    if (project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) {
      return (
        <div className="bodyMediaPhotoSliderPage" style={{width: "100%", height: mediaHeight}}>
          <img src={media.url} className="bodyMediaPhotoSliderPageMedia" style={{width: "100%", height: mediaHeight}} />
        </div>
      );
    }

    return (
      <div className="bodyMediaPhotoSliderPage" style={{width: "100%", height: mediaHeight}}>
          <img
            src={media.url} 
            className="bodyMediaPhotoSliderPageMedia"
            style={{filter: "grayscale(100%)", width: "100%", height: mediaHeight}}
          />
      </div>
    );
  }

  renderMediasNavItem(media, index) {
    var project = this.state.project;
    if (project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) {
      return (
        <div key={index} className={this.state.currentPhotoSlideIndex == index ? "bodyMediaPhotoSliderNavigatorActivDot" : "bodyMediaPhotoSliderNavigatorInactivDot"}></div>
      );
    }

    return (
      <div key={index} className={this.state.currentPhotoSlideIndex == index ? "bodyMediaPhotoSliderNavigatorBlackDot" : "bodyMediaPhotoSliderNavigatorInactivDot"}></div>
    );
  }

  renderTitleMenu = () => {
    return (
      <div className="bodyMediaEmptyHeaderTitleMenu">
        <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressDetails}>
            <IoInformationCircleOutline style={{width: 20, height: 20, color: "#1D1D1B"}} />
          <span className="bodyMediaEmptyHeaderTitleMenuItemText">Open this project</span>
        </Link>
        { (this.props.signedUser != null && MongoProjectEngine.isTeamByUserId(this.state.project, this.props.signedUser.docId)) &&
          <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressEdit}>
            <SlPencil style={{color: "#1D1D1B", height: 20, width: 20}} />
            <span className="bodyMediaEmptyHeaderTitleMenuItemText">Edit this project</span>
          </Link>
        }
        { this.props.signedUser != null && MongoProjectEngine.isAdministratorByUserId(this.state.project, this.props.signedUser.docId) &&
          <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressDelete}>
            <FontAwesomeIcon icon={ faTrashCan } style={{color: "#1D1D1B", height: 20, width: 20}} />
            <span className="bodyMediaEmptyHeaderTitleMenuItemText">Delete this project</span>
          </Link>
        }
        { this.state.pinnedByMe == false &&
          <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressPin}>
            <SlPin style={{color: '#1D1D1B', width: 20, height: 20}} />
            <span className="bodyMediaEmptyHeaderTitleMenuItemText">Pin this project</span>
          </Link>
        }
        { this.state.pinnedByMe == true &&
          <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressPin}>
            <FontAwesomeIcon icon={ faUnlink } size={20} className="bodyMediaEmptyHeaderTitleMenuItemIcon" />
            <span className="bodyMediaEmptyHeaderTitleMenuItemText">Unpin this project</span>
          </Link>
        }
        <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressShare}>
          <MdLaunch style={{color: "#1D1D1B", width: 20, height: 20}} />
          <span className="bodyMediaEmptyHeaderTitleMenuItemText">Share external</span>
        </Link>
        { (this.props.signedUser != null && MongoProjectEngine.isTeamByUserId(this.state.project, this.props.signedUser.docId)) &&
          <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressPost}>
            <FaRetweet style={{fontSize: 18, width: 20, height: 20, color: '#1D1D1B'}} />
            <span className="bodyMediaEmptyHeaderTitleMenuItemText">Repost in update</span>
          </Link>
        }
        <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressClone}>
            <GoCopy style={{color: "#1D1D1B", width: 20, height: 20}} />
          <span className="bodyMediaEmptyHeaderTitleMenuItemText">Launch similar project</span>
        </Link>
        { this.props.isEnviromateProject == true &&
          <Link to="" className="bodyMediaEmptyHeaderTitleMenuItem" onClick={this.onPressDislike}>
            <GoCopy style={{color: "#1D1D1B", width: 20, height: 20}} />
            <span className="bodyMediaEmptyHeaderTitleMenuItemText">Remove project</span>
          </Link>
        }
      </div>
    );
  }

  renderStatus = () => {
    var project = this.state.project;
    if (project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) {
      return (
        <div className="bodyMediaEmptyFooterLeftItem">
          <Link to="" hitSlop={{top: 10, bottom: 10, left: 10, right: 10}} onClick={this.onPressUpdate}>
            <FontAwesomeIcon icon={ faFileLines } size={20} className="bodyMediaImageControlFooterLeftItemIcon"/>
          </Link>
          <span className="bodyMediaImageControlFooterLeftItemText">{ this.state.postCount }</span>
        </div>
      );
    }

    if (project.status == MongoProject.STATUS_DRAFT && this.state.preparedPercent < 100) {
      return (
        <div className="bodyMediaEmptyFooterLeftItem" style={{alignItems:"flex-start", flexDirection:"column"}}>
          <span className="bodyMediaImageControlFooterPercentText">{ Number(this.state.preparedPercent).toFixed(0) } %</span>
          <span className="bodyMediaImageControlFooterPercentTitleText">COMPLETE</span>
        </div>
      );
    }

    if (MongoProjectEngine.isCurated(project) != true) {
      return null;
    }

    if (project.status == MongoProject.STATUS_REVIEW) {
      return (
        <div className="bodyMediaEmptyFooterLeftItem" style={{flexDirection:"column"}}>
          <div className="bodyMediaImageControlFooterReview">
            <FontAwesomeIcon icon={ faClock } size={14} className="bodyMediaImageControlFooterReviewIcon"/>
          </div>
          <span className="bodyMediaImageControlFooterPercentTitleText" style={{marginTop: 5}}>REVIEW</span>
        </div>
      );
    }

    return (
      <div className="bodyMediaEmptyFooterLeftItem" style={{flexDirection:"column"}}>
        <div className="bodyMediaImageControlFooterFeedback">
          <FontAwesomeIcon icon={ faCircleInfo } size={24} className="bodyMediaImageControlFooterFeedbackIcon"/>
        </div>
        <span className="bodyMediaImageControlFooterPercentTitleText" style={{marginTop: 5}}>FEEDBACK</span>
      </div>
    );
  }

  renderMember = (member, index, rightMargin) => {
    if (member.type == MongoTeam.TYPE_INVITED) {
      return null;
    }

    return (
      <Link to="" key={index} className="bodyTeamMember" style={{marginRight:rightMargin}} onClick={() => this.onPressMember(member)}>
        { member.memberLogoUrl ? (
          <div className="bodyTeamMemberLogo">
            <img src={member.memberLogoUrl}
              className="bodyTeamMemberLogoIcon"
            />
          </div>
        ) : (
          <FontAwesomeIcon icon={ faUserCircle } size={30} className="bodyTeamMemberLogoEmpty"/>
        )}
      </Link>
    );
  }

  renderAction = () => {
    var project = this.state.project;
    if (this.props.fundedPercent >= 100) {
      return (
        <Link to="" className="bodyBack" onClick={this.onPressDetails}>
          <span className="bodyBackText">View this project</span>
        </Link>
      );
    }

    if (this.props.preview == true) {
      return (
        <Link to="" className="bodyBack" onClick={this.onPressPledge}>
          <span className="bodyBackText">
            { this.props.showMembers == true ? "Please support us on our Journey" : "Back this project" }
          </span>
        </Link>
      );
    }

    if (this.state.project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) {
      return (
        <Link to="" className="bodyBack" onClick={this.onPressPledge}>
          <span className="bodyBackText">
            { this.props.showMembers == true ? "Please support us on our Journey" : "Back this project" }
          </span>
        </Link>
      );
    } else if (this.props.signedUser != null && MongoProjectEngine.isTeamByUserId(this.state.project, this.props.signedUser.docId) == true) {
      return (
        <Link to="" className="bodyBack" onClick={this.onPressSetup}>
          <span className="bodyBackText">
            { (project.status == MongoProject.STATUS_DRAFT && this.state.preparedPercent < 100) ? "Complete project in order to launch it" : "See annotations in order to launch" }
          </span>
        </Link>
      );
    }

    return (
      <Link to="" className="bodyBack" onClick={this.onPressDetails}>
        <span className="bodyBackText">View this project</span>
      </Link>
    );
  }

  render = () => {
    this.videoPlayers = [];
    var project = this.state.project;
    var memberCount = 1;
    project.members.forEach(member => {
      if (member.type == MongoTeam.TYPE_INVITED) {
        return;
      }

      memberCount++;
    });

    var memberRightMargin = teamMemberRightMargin;
    var memberIconCount = Math.floor((this.state.panelWidth - bodyHorizontalMargin * 2 + teamMemberRightMargin) / (teamMemberIconWidth + teamMemberRightMargin));
    if (memberIconCount < memberCount) {
      memberRightMargin = Math.floor((this.state.panelWidth - bodyHorizontalMargin * 2) / memberIconCount);
      memberIconCount = memberIconCount - 1;
    }

    var members = [];
    var creator = MongoTeamEngine.getCreatorByProject(project);
    members.push(creator);

    var count = 1;
    for (var i = 0; i < project.members.length; i++) {
      if (count >= memberIconCount) {
        break;
      }

      var member = project.members[i];
      if (member.type == MongoTeam.TYPE_INVITED) {
        continue;
      }

      members.push(member);

      count++;
    }

    var project = this.state.project;

    return (
      <div
        key={this.props.key}
        //onLayout={this.onPanelSizeUpdated}
        ref='viewShot'
        className="panel"
      >
        <div
          onClick={() => this.onPressEmpty()}
          className="panelPress"
        >
          <div className="bodyMedia">
            {this.renderMedias()}
          </div>

          { (project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) &&
            <div className="bodyProgressBar">
              <div className="bodyProgressBarActive"></div>
              <div className="bodyProgressBarInactive"></div>
            </div>
          }

          { (project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) &&
            <div className="bodyInfo">
              <div className="bodyInfoItemFund">
                { project.funding.crowdFunding &&
                  <span className="bodyInfoItemText">{this.props.fundedPercent >= 100 ? "Funded" : this.props.fundedPercent + "% funded"}</span>
                }
              </div>
              <div className="bodyInfoItemBackers">
                { project.funding.crowdFunding &&
                  <div className="bodyInfoItemText">{this.state.supporterCount} Supporters</div>
                }
              </div>
              <div className="bodyInfoItemFollowWrap">
                <div className="bodyInfoFollowInactive">
                  <span className="bodyInfoFollowIactiveText">{ this.state.followerCount } Followers</span>
                </div>
                {/*  this.props.preview != true && this.props.signedUser != null && MongoProjectEngine.isTeamByUserId(project, this.props.signedUser.docId) == true ? (
                  <div className="bodyInfoFollowInactive">
                    <span className="bodyInfoFollowIactiveText">{ this.state.followerCount } Followers</span>
                  </div>
                ) : (
                  <div className="bodyInfoFollow" onClick={this.onPressFollow}>
                    <span className="bodyInfoFollowText">
                      { this.props.signedUser != null && this.state.followedByMe == true ? "Unfollow" : "Follow" }
                    </span>
                  </div>
                ) */}
              </div>
            </div>
          }

          { ((project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) && project.summary != "") &&
            <div className="bodyInfo" onClick={this.onPressSummary}>
              <span className="bodySummaryText">{project.summary}</span>
            </div>
          }

          { ((project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) && memberCount > 0) &&
            <div className="bodyInfo" style={{flexDirection:"column"}}>
              <div className="bodyTeamTitle">
                <span className="bodyTeamTitleText">Team</span>
              </div>
              <div className="bodyTeamMembers">
                {members.map((member, index) => this.renderMember(member, index, memberRightMargin))}
                { members.length < memberCount &&
                  <div className="bodyTeamMemberCount">
                    <span className="bodyTeamMemberCountText">+{memberCount - members.length}</span>
                  </div>
                }
              </div>
            </div>
          }

          { (project.status == MongoProject.STATUS_ONLINE || this.props.preview == true) &&
            <div className="bodyInfo">
            </div>
          }

          { this.renderAction() }

          {/* this.state.loading &&
            <div style={GlobalStyles.spinner}>
              <ActivityIndicator color="#72C500" size='large'/>
            </div>
          */}

          {/* this.state.showPledgeModal &&
            <ProjectPledgeModal
              modalVisible={this.state.showPledgeModal}
              onCancel={this.onClosePledgeModal}
              onOK={this.onPledged}
              project={this.state.project}
              user={this.props.signedUser}
            />
          */}
          <Snackbar
            open={this.state.isShowToast}
            autoHideDuration={3000}
            onClose={this.handleToastClose}
            message={this.state.toastMsg}
          />
        </div>
      </div>
    );
  }
}

export default ProjectPanel;