import React, { Component } from "react";
import axios from 'axios';
import qs from 'qs';

import "../../App.css";
import "./paypal.css";

const base_live_url = "https://us-central1-yamu-dev.cloudfunctions.net/api";
const base_test_url = "http://127.0.0.1:5001/yamu-dev/us-central1/api";

const client_live_id = "AfTZRUCwt52BIUs2BelS6U1iUqvtpe8zQtwucD8Vji4Y0hJtLv6Hy8v2yyuOXERGON8ntP04YCa8D9Sa";
const client_test_id = "Aco1DwwSC_PYywPyGXIDqlyxmGxMQ54lyCRwzmrquzHtlmfs0qJfejAsN9rbydh-ket9inmr8aRALSYz";

const paypal_test_url = "https://api-m.sandbox.paypal.com";
const paypal_live_url = "https://api-m.paypal.com";

const isDev = (window.location.port === '5000' || window.location.port === '3000') ? true : false;
console.log('isDev', isDev);
const base_url = isDev ? base_test_url : base_live_url;
const client_id = isDev ? client_test_id : client_live_id;
const paypal_url = isDev ? paypal_test_url : paypal_live_url;

class PaypalAuth extends Component {
  constructor(props) {
    super(props);

    var code = "";
    try {
      code = this.props.searchParams.get("code");
    } catch(error) {}

    this.state = {
      code: code,
      isAuthorizing: true,
      userInfo: null,
    };
  }

  componentDidMount = async () => {
    await this.refresh();
  }

  focusScreen = async () => {
    await this.refresh();
  }

  refresh = async () => {
    var userInfo = null;
    var code = this.state.code;

    if (code != null) {
      var accessToken = await this.getAccessToken(code);
      if (accessToken != "") {
        userInfo = await this.getUserInfo(accessToken);
      }
    }

    this.setState({
      isAuthorizing: false,
      userInfo: userInfo,
    });
  }

  getAccessToken = async (code) => {
    var accessToken = "";
    try {
      // var data = qs.stringify({
      //   'grant_type': 'authorization_code',
      //   'code': code 
      // });

      // var config = {
      //   method: 'post',
      //   url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token',
      //   headers: {
      //     'Authorization': 'Basic QWNvMUR3d1NDX1BZeXdQeUdYSURxbHl4bUd4TVE1NGx5Q1J3em1ycXV6SHRsbWZzMHFKZmVqQXNOOXJieWRoLWtldDlpbm1yOGFSQUxTWXo6RUxJNm1FaFBEU0RQaEI1RUNUZVZPWmg1QlNzLUhMU084eDVFcTM2aWM5bTdINFRxYzZJTF80bWxEVENoNDh3ME9Nd0x3ZWktNS1SOUQzNlE=', 
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   },
      //   data : data
      // };

      // var response = await axios(config);
      // var json = response.data;
      // if (json.access_token) {
      //   accessToken = json.access_token;
      // }
      const response = await fetch(`${base_url}/paypal/token`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if(json.access_token) {
        accessToken = json.access_token;
      }
    } catch(error) {
      console.log("[Auth] getAccessToken() -> error: " + error.message);
    }

    return accessToken;
  }

  getUserInfo = async (accessToken) => {
    var userInfo = null;
    try {
      var data = qs.stringify({});
      var config = {
        method: 'get',
        url: paypal_url + '/v1/identity/oauth2/userinfo?schema=paypalv1.1',
        headers: {
          'Authorization': 'Bearer ' + accessToken
        },
        data : data
      };

      var response = await axios(config);
      userInfo = response.data;
    } catch(error) {
      console.log("[Auth] getUserInfo() -> error: " + error.message);
    }

    return userInfo;
  }

  render = () => {
    if (this.state.code == null) {
      return (
        <div className="App">
          <div className="PaypalContent" id='lippButton'></div>
        </div>
      );
    }

    if (this.state.isAuthorizing) {
      return (
        <div className="App">
          <div className="PaypalContent">
            <div className="PaypalText">Please wait while authorizing your paypal account...</div>
          </div>
        </div>
      );
    }

    if (this.state.userInfo == null) {
      return (
        <div className="App">
          <div className="PaypalContent">
            <div className="PaypalText">
              Failed to authorize your paypal account.
              <br /><br />
              <a id="aReturn" href={"/paypal/auth"}>Please try again.</a>
            </div>
          </div>
        </div>
      );
    }

    // tell app
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(this.state.userInfo));
    }

    return (
      <div className="App">
        <div className="PaypalContent">
          <div className="PaypalText">Your paypal account has been successfully connected.</div>
        </div>
      </div>
    );
  }
}

export default PaypalAuth;
